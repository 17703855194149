/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
// import { CustomerState } from '@/store/customer/types';
import { ModelAccountState } from '@/store/modelAccount/types';
const namespace: string = 'modelAccount';

export default class ModelAccountAgreementPpPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;

	acceptAgreement: boolean = false;

	onSubmit() {
		if(this.acceptAgreement) {
			this.$router.push({name: 'model-account-my-account'}).then(r => r);
		}
	}
}
